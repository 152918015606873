import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

interface Avatar {
    filename: string;
    alt: string;
}

interface FloatingAvatarsProps {
    avatars: Avatar[];
}

const generateNonOverlappingPositions = (
    count: number,
    minDistance: number
) => {
    const positions = [];
    const maxAttempts = 100;
    let attempts = 0;

    while (positions.length < count && attempts < maxAttempts) {
        attempts++;
        const newPos = {
            top: Math.random() * 100,
            left: Math.random() * 100
        };
        const overlap = positions.some(pos => {
            const distance = Math.sqrt(
                Math.pow(newPos.top - pos.top, 2) +
                    Math.pow(newPos.left - pos.left, 2)
            );
            return distance < minDistance;
        });

        if (!overlap) {
            positions.push(newPos);
        }
    }

    return positions;
};

const FloatingAvatars: React.FC<FloatingAvatarsProps> = ({ avatars }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [positions, setPositions] = useState<{ top: number; left: number }[]>(
        []
    );

    useEffect(() => {
        if (containerRef.current) {
            const newPositions = generateNonOverlappingPositions(
                avatars.length,
                20
            );
            setPositions(newPositions);

            const gridItems =
                containerRef.current.querySelectorAll(".floating-avatar");

            gridItems.forEach(item => {
                const yPercentRandomVal = gsap.utils.random(-500, 500);

                gsap.timeline().to(item, {
                    ease: "none",
                    yPercent: yPercentRandomVal,
                    duration: 2,
                    scrollTrigger: {
                        trigger: containerRef.current,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true
                    }
                });
            });
        }
    }, [avatars]);

    return (
        <div
            ref={containerRef}
            className="relative w-full h-full overflow-hidden"
        >
            {avatars.map((avatar, index) => (
                <img
                    key={index}
                    src={avatar.filename}
                    alt={avatar.alt}
                    className="floating-avatar absolute h-14 w-14 md:h-20 md:w-20 lg:h-24 lg:w-24 xl:h-28 xl:w-28 object-cover"
                    style={{
                        top: `${positions[index]?.top || 0}%`,
                        left: `${positions[index]?.left || 0}%`,
                        transform: `translate(-${gsap.utils.random(0, 10)}%, -${gsap.utils.random(0, 10)}%)`
                    }}
                />
            ))}
        </div>
    );
};

export default FloatingAvatars;
